import { useState, useRef, useEffect, createContext } from "react";
import { About } from "./components/info/About.js";
import { RoomDeleted } from "./components/RoomDeleted";

import { Contact } from "./components/info/Contact.js";
import { TermsOfService } from "./components/info/TermsOfService.js";
import { Credits } from "./components/info/Credits.js";
import React, { Suspense, lazy } from 'react';

import { Howl } from "howler";
import clockSoundFile from "./sounds/clock.mp3";
import finalTimerSoundFile from "./sounds/finalTimer.mp3";
import typingSoundSoundFile from "./sounds/typingSound.mp3";
import playerJoinSoundFile from "./sounds/playerJoin.mp3";
import booSoundFile from "./sounds/boo.mp3";


import germanQuestionData from "./germanQuestions.json"
import englishQuestionData from "./englishQuestions.json"
import germanEstimationQuestionsData from "./germanEstimationQuestions.json"
import englishEstimationQuestionsData from "./englishEstimationQuestions.json"




import { Auth } from "./components/Auth.js";
import { db } from "./firebase-config.js";
import {
  ref,

  onValue,
} from "firebase/database";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { WinnerScreen } from "./components/WinnerScreen.js";
import { useNavigate } from "react-router-dom";

const Lobby = lazy(() =>
  import('./components/Lobby').then((module) => ({ default: module.Lobby }))
);

const Game = lazy(() =>
  import('./components/Game').then((module) => ({ default: module.Game }))
);

const Voting = lazy(() =>
  import('./components/Voting').then((module) => ({ default: module.Voting }))
);

const Final = lazy(() =>
  import('./components/Final').then((module) => ({ default: module.Final }))
);



export const AppContext = createContext();

/*

{
  "rules": {
    "rooms": {
      "$roomID": {
        ".write": "auth != null && (data.child('players').child(auth.uid).exists() || !data.exists() || newData.child('players').child(auth.uid).exists())",
        ".read": "auth != null"
      }
    }
  }
}


*/

/*
const deleteAllRooms = async () => {
  try {
    const roomsRef = ref(db, 'rooms');
    const snapshot = await get(roomsRef);
    if (snapshot.exists()) {
      const rooms = snapshot.val();
      for (const roomID in rooms) {
        if (rooms.hasOwnProperty(roomID)) {
          await remove(ref(db, `rooms/${roomID}`));
        }
      }
    } else {
    }
  } catch (error) {
    console.error(error);
  }
};

// Beispiel: Lösche alle Räume
deleteAllRooms();
*/
function App() {
  const [shouldJoin, setShouldJoin] = useState(true);
  const [showVoting, setShowVoting] = useState(false);
  const [inLobby, setInLobby] = useState(true);
  const [showWinnerScreen, setShowWinnerScreen] = useState(false);
  const [showFinalScreen, setShowFinalScreen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  return (
    <div className="App">
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Auth
                  shouldJoin={shouldJoin}
                  setShouldJoin={setShouldJoin}
                  isMuted={isMuted}
                  setIsMuted={setIsMuted}
                />
              }
            />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms-and-conditions" element={<TermsOfService />} />
            <Route path="/credits" element={<Credits />} />

            <Route
              path="/room/:id"
              element={
                <Room
                  isMuted={isMuted}
                  setIsMuted={setIsMuted}
                  showVoting={showVoting}
                  shouldJoin={shouldJoin}
                  setShouldJoin={setShouldJoin}
                  setShowVoting={setShowVoting}
                  inLobby={inLobby}
                  setInLobby={setInLobby}
                  showWinnerScreen={showWinnerScreen}
                  setShowWinnerScreen={setShowWinnerScreen}
                  showFinalScreen={showFinalScreen}
                  setShowFinalScreen={setShowFinalScreen}
                />
              }
            />
            <Route path="/room-deleted" element={<RoomDeleted />} />
          </Routes>
        </Router>
    </div>
  );
}

function Room({
  showWinnerScreen,
  setShowWinnerScreen,
  showVoting,
  setShowVoting,
  inLobby,
  setInLobby,
  shouldJoin,
  setShouldJoin,
  showFinalScreen,
  setShowFinalScreen,
  isMuted,
  setIsMuted,
}) {
  const url = window.location.pathname;

  const parts = url.split("/");
  const roomID = parts[2];
  const [isCreator, setIsCreator] = useState(false);
  const [lives, setLives] = useState(2);
  const [rounds, setRounds] = useState(2);
  const [questionTime, setQuestionTime] = useState(30);
  const [votingTime, setVotingTime] = useState(45);
  const [playerNumber, setPlayerNumber] = useState(0);
  const [players, setPlayers] = useState([]);
  const [volume, setVolume] = useState(50);

  const [winner, setWinner] = useState();
  const [finalists, setFinalists] = useState([]);

  const [isFirstSound, setIsFirstSound] = useState(true);

  const [language, setLanguage] = useState("german");
  const [votingNumber, setVotingNumber] = useState(1);
  const listener1Ref = useRef(null);
  const listener2Ref = useRef(null);
  const listener3Ref = useRef(null);
  const listener4Ref = useRef(null);
  const listener5Ref = useRef(null);

  const getAllQuestions = (data) => {
    return data.categories.flatMap((category) => category.questions);
  };

  const [remainingEnglishQuestions, setRemainingEnglishQuestions] = useState(getAllQuestions(englishQuestionData));
                  
  const [remainingGermanQuestions, setRemainingGermanQuestions] = useState(getAllQuestions(germanQuestionData));

  // Funktion, um alle Fragen aus den Kategorien zusammenzuführen

  // Funktion, um eine zufällige Frage und die dazugehörige Antwort auszuwählen
  const getRandomGermanQuestion = () => {
    const randomIndex = Math.floor(Math.random() * remainingGermanQuestions.length);
    const randomQuestion = remainingGermanQuestions.splice(randomIndex, 1)[0]; // Frage entfernen und zurückgeben
    if (!remainingGermanQuestions || remainingGermanQuestions.length === 0) {
      setRemainingGermanQuestions(getAllQuestions(germanQuestionData)); // setze den Zustand mit den neuen Fragen
    }

    return randomQuestion;
  };

  const getRandomEnglishQuestion = () => {
    const randomIndex = Math.floor(Math.random() * remainingEnglishQuestions.length);
    const randomQuestion = remainingEnglishQuestions.splice(randomIndex, 1)[0]; // Frage entfernen und zurückgeben
    if (!remainingEnglishQuestions || remainingEnglishQuestions.length === 0) {
      setRemainingEnglishQuestions(getAllQuestions(englishQuestionData)); // setze den Zustand mit den neuen Fragen
    }

    return randomQuestion;
  };

  const [remainingEnglishEstimationQuestions, setRemainingEnglishEstimationQuestions] = useState(englishEstimationQuestionsData.questions);
                                    
  const [remainingGermanEstimationQuestions, setRemainingGermanEstimationQuestions] = useState(germanEstimationQuestionsData.questions);

  const getRandomGermanEstimationQuestion = () => {
    const randomIndex = Math.floor(Math.random() * remainingGermanEstimationQuestions.length);
    const randomQuestion = remainingGermanEstimationQuestions.splice(randomIndex, 1)[0]; // Frage entfernen und zurückgeben
    if (!remainingGermanEstimationQuestions || remainingGermanEstimationQuestions.length === 0) {
      setRemainingGermanEstimationQuestions(germanEstimationQuestionsData.questions); // setze den Zustand mit den neuen Fragen
    }

    return randomQuestion;
  };

  const getRandomEnglishEstimationQuestion = () => {
    const randomIndex = Math.floor(Math.random() * remainingEnglishEstimationQuestions.length);
    const randomQuestion = remainingEnglishEstimationQuestions.splice(randomIndex, 1)[0]; // Frage entfernen und zurückgeben
    if (!remainingEnglishEstimationQuestions || remainingEnglishEstimationQuestions.length === 0) {
      setRemainingEnglishEstimationQuestions(englishEstimationQuestionsData.questions); // setze den Zustand mit den neuen Fragen
    }
    return randomQuestion;
  };


  // https://pixabay.com/sound-effects/search/quiz/
  // https://pixabay.com/sound-effects/system-notification-199277/
  const clockSound = new Howl({
    src: [clockSoundFile],
    volume: 0.25,
  });

  // https://pixabay.com/music/build-up-scenes-thinking-time-148496/
  const finalTimerSound = new Howl({
    volume: 0.05,
    src: [finalTimerSoundFile],
  });

  //  https://pixabay.com/sound-effects/typing-sound-02-229861/
  const typingSound = new Howl({
    volume: 0.25,
    src: [typingSoundSoundFile],
  });

  // https://pixabay.com/de/sound-effects/ding-sound-246413/
  const playerJoinSound = new Howl({
    volume: 0.25,
    src: [playerJoinSoundFile],
  });

  const booSound = new Howl({
    volume: 0.25,
    src: [booSoundFile],
  });

  /*
  useEffect(() => {
    clockSound.volume(volume);
  }, [volume]);
 */
  const navigate = useNavigate();

  useEffect(() => {
    const activeRef = ref(db, `rooms/${roomID}/active`);
    const unsubscribe = onValue(activeRef, (snapshot) => {
      const active = snapshot.val();
      if (active === "deleted" || active === null) {
        navigate("/room-deleted", {
          active: {
            message:
              "The room no longer exists or was closed due to inactivity.",
          },
        });
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <div>
      {showWinnerScreen ? (
        <WinnerScreen
          isMuted={isMuted}
          setIsMuted={setIsMuted}
          setIsCreator={setIsCreator}
          setPlayers={setPlayers}
          players={players}
          volume={volume}
          setVolume={setVolume}
          winner={winner}
          setInLobby={setInLobby}
          setShowWinnerScreen={setShowWinnerScreen}
          setShowVoting={setShowVoting}
          roomID={roomID}
          lives={lives}
          rounds={rounds}
          questionTime={questionTime}
          votingTime={votingTime}
          isCreator={isCreator}
          setVotingNumber={setVotingNumber}
          setShouldJoin={setShouldJoin}
          setShowFinalScreen={setShowFinalScreen}
          listener1Ref={listener1Ref}
          listener2Ref={listener2Ref}
          listener3Ref={listener3Ref}
          listener4Ref={listener4Ref}
          listener5Ref={listener5Ref}
        />
      ) : showFinalScreen ? (
        <Final
          getRandomGermanEstimationQuestion = {getRandomGermanEstimationQuestion}
          getRandomEnglishEstimationQuestion = {getRandomEnglishEstimationQuestion}

          isMuted={isMuted}
          setIsMuted={setIsMuted}
          clockSound={clockSound}
          typingSound={typingSound}
          finalTimerSound={finalTimerSound}
          setShowWinnerScreen={setShowWinnerScreen}
          setWinner={setWinner}
          setPlayers={setPlayers}
          players={players}
          isCreator={isCreator}
          roomID={roomID}
          language={language}
          finalist1={finalists[0]}
          finalist2={finalists[1]}
          setIsCreator={setIsCreator}
          listener1Ref={listener1Ref}
          listener2Ref={listener2Ref}
          listener3Ref={listener3Ref}
          listener4Ref={listener4Ref}
          listener5Ref={listener5Ref}
        />
      ) : inLobby ? (
        <Lobby
          isMuted={isMuted}
          setIsMuted={setIsMuted}
          isFirstSound={isFirstSound}
          setIsFirstSound={setIsFirstSound}
          playerJoinSound={playerJoinSound}
          setInLobby={setInLobby}
          volume={volume}
          listener1Ref={listener1Ref}
          listener2Ref={listener2Ref}
          listener3Ref={listener3Ref}
          listener4Ref={listener4Ref}
          listener5Ref={listener5Ref}
          setVolume={setVolume}
          isCreator={isCreator}
          setIsCreator={setIsCreator}
          lives={lives}
          setLives={setLives}
          rounds={rounds}
          setRounds={setRounds}
          questionTime={questionTime}
          setQuestionTime={setQuestionTime}
          votingTime={votingTime}
          setVotingTime={setVotingTime}
          playerNumber={playerNumber}
          roomID={roomID}
          setPlayerNumber={setPlayerNumber}
          players={players}
          setPlayers={setPlayers}
          shouldJoin={shouldJoin}
          setShouldJoin={setShouldJoin}
          language={language}
          setLanguage={setLanguage}
        />
      ) : showVoting ? (
        <Voting
          isMuted={isMuted}
          setIsMuted={setIsMuted}
          booSound={booSound}
          clockSound={clockSound}
          setShouldJoin={setShouldJoin}
          setInLobby={setInLobby}
          listener1Ref={listener1Ref}
          listener2Ref={listener2Ref}
          listener3Ref={listener3Ref}
          listener4Ref={listener4Ref}
          listener5Ref={listener5Ref}
          setIsCreator={setIsCreator}
          volume={volume}
          setVolume={setVolume}
          setWinner={setWinner}
          setShowWinnerScreen={setShowWinnerScreen}
          showVoting={showVoting}
          setShowVoting={setShowVoting}
          votingTime={votingTime}
          isCreator={isCreator}
          players={players}
          votingNumber={votingNumber}
          roomID={roomID}
          setPlayers={setPlayers}
          setVotingNumber={setVotingNumber}
          setShowFinalScreen={setShowFinalScreen}
          setFinalists={setFinalists}
        />
      ) : (
        <Game
          getRandomGermanQuestion={getRandomGermanQuestion}
          getRandomEnglishQuestion={getRandomEnglishQuestion}

          isMuted={isMuted}
          setIsMuted={setIsMuted}
          typingSound={typingSound}
          clockSound={clockSound}
          setShowFinalScreen={setShowFinalScreen}
          setFinalists={setFinalists}
          setInLobby={setInLobby}
          setShouldJoin={setShouldJoin}
          listener1Ref={listener1Ref}
          listener2Ref={listener2Ref}
          listener3Ref={listener3Ref}
          listener4Ref={listener4Ref}
          listener5Ref={listener5Ref}
          setShowWinnerScreen={setShowWinnerScreen}
          setWinner={setWinner}
          language={language}
          volume={volume}
          setVolume={setVolume}
          setShowVoting={setShowVoting}
          isCreator={isCreator}
          setIsCreator={setIsCreator}
          lives={lives}
          rounds={rounds}
          questionTime={questionTime}
          votingTime={votingTime}
          playerNumber={playerNumber}
          players={players}
          db={db}
          roomID={roomID}
          setPlayers={setPlayers}
          votingNumber={votingNumber}
          setVotingNumber={setVotingNumber}
        />
      )}
    </div>
  );
}

export default App;
