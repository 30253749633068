export const TermsOfService = () => {
    return (
<div>
The Terms of Service will be available here soon.
</div>


    )
    
}
