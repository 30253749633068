import React, { useEffect, useState } from "react";
import soundLoud from "../../styles/images/soundLoud.svg";
import soundMute from "../../styles/images/soundMute.svg";
import { Howler } from "howler";

export const SoundToggle = ({ className, isMuted, setIsMuted }) => {

  useEffect(() => {
    if (isMuted) {
        Howler.mute(true);
    } else{
        Howler.mute(false);
    }
  }, [isMuted]);


  const toggleSound = () => {
    setIsMuted(!isMuted);

  };

  return (
    <div
      className={className}
      onClick={toggleSound}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        /*outline: "1px solid #ccc",*/
        borderRadius: "50%",
      }}
    >
      {isMuted ? (
        <img
          className="test"
          src={soundMute}
          style={{
            width: "3.5vw",
            height: "3.5vw",
          }}
        />
      ) : (
        <img
          className="test"
          src={soundLoud}
          style={{
            width: "3.5vw",
            height: "3.5vw",
          }}
        />
      )}
    </div>
  );
};

export default SoundToggle;
