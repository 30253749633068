import "../styles/WinnerScreen.css";

import { db, uid } from "../firebase-config.js";
import { createAvatar } from "@dicebear/core";
import { bigSmile } from "@dicebear/collection";

import { useEffect, useState, useMemo, useRef } from "react";
import { resetRoom } from "../utilities/helperFunctions.js";
import { SoundToggle } from "./sections/SoundToggle.js";

import {
  onValue,
  set,
  ref,
  onDisconnect,
  remove,
  serverTimestamp,
} from "firebase/database";
import {
  getPlayerById,
  creatorExists,
  removeAllOfflinePlayers,
  getAlivePlayers,
  wait,
} from "../utilities/helperFunctions.js";
import trophy from "../styles/images/trophy.svg";
import avatarBackground2 from "../styles/images/avatarBackground2.svg";
import { Howler } from "howler";

import creatorCrown from "../styles/images/creatorCrown.svg";

export const WinnerScreen = ({
  isMuted,
  setIsMuted,

  winner,
  setInLobby,
  setShowWinnerScreen,
  setShowVoting,
  roomID,
  lives,
  rounds,
  questionTime,
  votingTime,
  isCreator,
  setVotingNumber,
  setIsCreator,
  setPlayers,
  players,

  setShouldJoin,
  setShowFinalScreen,

  listener1Ref,
  listener2Ref,
  listener3Ref,
  listener4Ref,
  listener5Ref,
}) => {
  const [backToLobby, setBackToLobby] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showMessage, setShowMessage] = useState(false); // Zustand für die Nachricht

  const playersRef = useRef(players);
  const isCreatorRef = useRef(isCreator);

  // for onDisconnect variables

  const [currentUid, setCurrentUid] = useState(uid);

  const prevPlayerRef = useRef(null);

  useEffect(() => {
    isCreatorRef.current = isCreator;
  }, [isCreator]);

  useEffect(() => {
    playersRef.current = players;
  }, [players]);

  useEffect(() => {
    if (currentUid) {
      if (prevPlayerRef.current) {
        onDisconnect(prevPlayerRef.current).cancel();
      }
      const playerRef = ref(db, "rooms/" + roomID + "/players/" + currentUid);
      onDisconnect(playerRef).remove();
      prevPlayerRef.current = playerRef;
    }
    return () => {
      if (prevPlayerRef.current) {
        onDisconnect(prevPlayerRef.current).cancel();
      }
    };
  }, [currentUid]);

  useEffect(() => {
    // Stoppt alle laufenden Howl-Instanzen
    Howler._howls.forEach((howl) => howl.stop());

    const tmp = async () => {
      await wait(2000);
      setShowButton(true);
    };

    tmp();

    if (listener1Ref.current) {
      window.removeEventListener("popstate", listener2Ref.current);
    }
    if (listener2Ref.current) {
      window.removeEventListener("popstate", listener3Ref.current);
    }
    if (listener3Ref.current) {
      window.removeEventListener("popstate", listener4Ref.current);
    }
    if (listener5Ref.current) {
      window.removeEventListener("popstate", listener5Ref.current);
    }

    // onDisconnects werden nur beim zurück navigieren getrigered wenn man von der Seite runter navigiert
    // um auch handere zurück pfeile zu handeln dieses zeug
    // popstate wird allerdings auch bei vorwärtspfeil getrigered was mögliche buggs verursacht
    // man braucht listener1Ref um in einem anderen Component diesen listener canceln zu können
    // man kann nicht in diesem komponent canceln da sonst beim zurück pfeil gecancelt wird bevor handePopstate ausgeführt wird
    function handlePopState4() {
      remove(ref(db, "rooms/" + roomID + "/players/" + currentUid));
      setShowWinnerScreen(false);
      setInLobby(true);
      setShouldJoin(true);
    }

    listener4Ref.current = handlePopState4;

    // Füge den Event-Listener hinzu
    window.addEventListener("popstate", handlePopState4);

    const playersRef = ref(db, "rooms/" + roomID + "/players");
    const unsubscribePlayersListener = onValue(playersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const playersArray = Object.entries(data).map(
          ([playerID, playerData]) => {
            const votedBy = playerData.votedBy
              ? Object.keys(playerData.votedBy)
              : [];
            return { playerID: playerID, ...playerData, votedBy: votedBy };
          }
        );
        setPlayers(playersArray);
        if (getPlayerById(uid, playersArray).isCreator) {
          setIsCreator(false);
        }

        // man muss sortieren da sonst manchmal bugg wenn gleicher name
        //ne sortieren kann man eigentlich lassen
        const alivePlayers = getAlivePlayers(playersArray);
        const sortedPlayers = alivePlayers.sort((a, b) =>
          a.playerID.localeCompare(b.playerID)
        );
        if (!creatorExists(sortedPlayers)) {
          set(
            ref(
              db,
              "rooms/" +
                roomID +
                "/players/" +
                sortedPlayers[0].playerID +
                "/isCreator"
            ),
            true
          );
        }
      }
    });

    const backToGameRef = ref(db, "rooms/" + roomID + "/status/backToLobby");
    const unsubscribeBackToLobbyListener = onValue(
      backToGameRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setBackToLobby(data);
        }
      }
    );

    setInLobby(true);
    setShowVoting(false);
    setShowFinalScreen(false);

    setVotingNumber(1);

    // Cleanup function to remove the listener when the component unmounts
    return () => {
      unsubscribeBackToLobbyListener();
    };
  }, []);

  const avatar = useMemo(() => {
    return createAvatar(bigSmile, {
      size: 70,
      seed: winner.playerName,
      backgroundColor: [],
    }).toDataUri();
  }, [winner]);

  useEffect(() => {
    if (backToLobby) {
      set(ref(db, "rooms/" + roomID + "/lastActivity"), serverTimestamp());
      setShowWinnerScreen(false);
    }
  }, [backToLobby]);

  const transitionToLobby = async () => {
    
    setIsButtonDisabled(true);

    if (!isCreatorRef.current) {
      // reset firebase
      await resetRoom(roomID, lives, rounds, questionTime, votingTime);
      if (playersRef.current) {
        removeAllOfflinePlayers(playersRef.current, roomID);
      }
      //reset variables

      set(ref(db, "rooms/" + roomID + "/status/backToLobby"), true);
    }
  };
  const handleClick = async () => {
    console.log("0")

    if(isCreatorRef.current){
      console.log("1")
      setShowMessage(true);

      // Nachricht nach 3 Sekunden wieder ausblenden
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    }

  }
  

  return (
    <div className="WinnerScreen-container">
      <SoundToggle
        className={"Auth-SoundToggle"}
        isMuted={isMuted}
        setIsMuted={setIsMuted}
      />

      <div className="WinnerScreen-confetti-layer">
        <img className="WinnerScreen-trophy" src={trophy} />

        <div
          className="WinnerScreen-Player"
          style={{
            outline: winner.playerID === uid ? "0.6vh solid #28B274" : "none",
          }}
        >
          {winner.isCreator && (
            <img className="Final-crown" src={creatorCrown} />
          )}
          <div className="WinnerScreen-player-avatar-container">
            <img className="Final-avatar-background" src={avatarBackground2} />
            <img className="Final-avatar" src={avatar} alt="Avatar" />
          </div>
          <h3 className="Final-player-name">{winner.playerName}</h3>
        </div>
        <div               className="WinnerScreen-button-div"
      onClick={handleClick}>
        {showButton && (
          <button
            disabled={isCreator || isButtonDisabled}
            className="WinnerScreen-button"
            onClick={transitionToLobby}
          >
            return to lobby
          </button>
        )}
        </div>
        {isCreator && showButton &&  (
          <div className="message-notice">only the creator can return you </div>
        )}
      </div>
    </div>
  );
};
