export const Credits = () => {
  return (
    <div>
      <p>
        This game is heavily inspired by the YouTube show 'Der Dümmste Fliegt'
        by the YouTuber{" "}
        <a
          href="https://www.youtube.com/@Reved"
          target="_blank"
          rel="noopener noreferrer"
        >
          Reved
        </a>
        , who in turn was strongly influenced by the YouTuber{" "}
        <a
          href="https://www.youtube.com/@pietsmiet"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pitesmiet
        </a>
        .
      </p>
      <p>
      <strong>Avatar Credit</strong><br />
      "Custom Avatar" by Ashley Seo is licensed under <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noopener noreferrer">CC BY 4.0</a>.<br />
      Source: <a href="https://www.figma.com/community/file/881358461963645496" target="_blank" rel="noopener noreferrer">Figma Community File</a><br />
      Homepage: <a href="http://www.ashleyseo.com/" target="_blank" rel="noopener noreferrer">ashleyseo.com</a>
    </p>
    </div>
  );
};
